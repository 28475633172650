import React, { useEffect, useRef } from "react";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Card,
    CardBody,
} from "reactstrap";
import { cache } from "swr";
import Avatar from "react-avatar";
import { useTranslation } from "react-i18next";
import jwt_decode from "jwt-decode";
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { SketchPicker } from "react-color";
import { useSnapshot } from "valtio";

import { store, setMainColor, setSecondaryColor } from "./store";

import { logError } from "../../helpers";
import KeySvgIcon from "@components/common/SvgIcons/KeySvgIcon";
import MagicSvgIcon from "@components/common/SvgIcons/MagicSvgIcon";
import { Tooltip } from "@mui/material";
import LogOutSvgIcon from "@components/common/SvgIcons/LogOutSvgIcon";
import RestoreSvgIcon from "@components/common/SvgIcons/RestoreSvgIcon";
import ColorSvgIcon from "@components/common/SvgIcons/ColorSvgIcon";

export default function ProfileMenu() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { mainColor, secondaryColor } = useSnapshot(store);

    const sketchPickerRef = useRef(null);
    const [menu, setMenu] = React.useState<boolean>(false);
    const [name, setName] = React.useState<string>("");
    const [userFirstName, setUserFirstName] = React.useState<any>("");
    const [userLastName, setUserLastName] = React.useState<any>("");
    const tokenUserTmp: any = localStorage.getItem("access_token_id");
    const [email, setEmail] = React.useState<any>("");
    const [userEmail, setUserEmail] = React.useState<any>("");

    const cryptDecryptDataUser = React.useCallback(
        async (wordToDecrypt: string, stateSetter: typeof Function) => {
            return await fetch(
                `${process.env.REACT_APP_ADMIN_ACCESS}/decryptWord`,
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    body: `wordToDecrypted=${wordToDecrypt}`,
                    method: "POST",
                }
            )
                .then((result) => result.text())
                .then((response: any) => {
                    stateSetter(response);
                    return response;
                })
                .catch((error) => {
                    logError("cryptDecryptDataUser ", error);
                    stateSetter("");
                    return "";
                });
        },
        []
    );
    React.useEffect(() => {
        if (tokenUserTmp != null) {
            let dataUserConnected: any = jwt_decode(tokenUserTmp);

            setUserFirstName(async () => {
                const firstName = await cryptDecryptDataUser(
                    dataUserConnected.sub.firstname,
                    setUserFirstName as typeof Function
                );
                return firstName;
            });
            setUserEmail(dataUserConnected.sub.email);
            setUserLastName(async () => {
                const lastName = await cryptDecryptDataUser(
                    dataUserConnected.sub.name,
                    setUserLastName as typeof Function
                );
                return lastName;
            });
        } else {
            setUserFirstName(async () => {
                const fisrtNameLocalStorage: any =
                    localStorage.getItem("firstName");
                const firstName = await cryptDecryptDataUser(
                    fisrtNameLocalStorage,
                    setUserFirstName as typeof Function
                );

                return firstName;
            });
            setUserLastName(async () => {
                const lastNameLocalStorage: any =
                    localStorage.getItem("lastName");
                const lastName = await cryptDecryptDataUser(
                    lastNameLocalStorage,
                    setUserLastName as typeof Function
                );

                return lastName;
            });
            setUserEmail(async () => {
                const emailLocalStorage: any = localStorage.getItem("email");
                const email = await cryptDecryptDataUser(
                    emailLocalStorage,
                    setUserEmail as typeof Function
                );

                return email;
            });
        }
    }, [tokenUserTmp, cryptDecryptDataUser]);

    React.useEffect(() => {
        if (userFirstName !== "" && userLastName !== "") {
            const fullName = `${userFirstName} ${userLastName}`;
            setName(fullName);
        } else if (localStorage.getItem("authUser")) {
            if (
                process.env.REACT_APP_DEFAULTAUTH === "fake" ||
                process.env.REACT_APP_DEFAULTAUTH === "jwt"
            ) {
                const obj = JSON.parse(localStorage.getItem("authUser")!);
                setName(
                    `${
                        obj.firstname
                            ? `${obj.firstname} ${obj.lastname}`
                            : obj.username
                    }`
                );
            }
        }

        if (userEmail !== "" && typeof userEmail !== "object") {
            setEmail(userEmail);
        }
        setMainColor(
            localStorage.getItem("mainColor") === undefined ||
                localStorage.getItem("mainColor") === "null" ||
                localStorage.getItem("mainColor") === ""
                ? "#171616"
                : localStorage.getItem("mainColor")
        );
        setSecondaryColor(
            localStorage.getItem("secondaryColor") === undefined ||
                localStorage.getItem("secondaryColor") === "null" ||
                localStorage.getItem("secondaryColor") === ""
                ? "#605e5d"
                : localStorage.getItem("secondaryColor")
        );
    }, [userEmail, userFirstName, userLastName]);

    const [colorMainPicker, setColorMainPicker] = React.useState(false);

    const [colorSecondaryPicker, setColorSecondaryMainPicker] =
        React.useState(false);

    function changePassword() {
        cache.clear();
        localStorage.clear();
        navigate("/update");
    }

    const handleChangeSecondaryColor = (color: any) => {
        setColorSecondaryMainPicker(!colorSecondaryPicker);
        setColorMainPicker(false);
    };

    const handleChangeMainColor = () => {
        setColorMainPicker(!colorMainPicker);
        setColorSecondaryMainPicker(false);
    };
    const handleResetColor = () => {
        localStorage.setItem("mainColor", "#171616");
        setMainColor("#171616");
        handleRequestColor("#171616", secondaryColor);
    };

    const handleSecondaryResetColor = () => {
        localStorage.setItem("secondaryColor", "#c4bfbe");
        setSecondaryColor("#c4bfbe");
        handleRequestColor(mainColor, "#c4bfbe");
    };

    function logout(): any {
        cache.clear();
        navigate("/login");
        localStorage.clear();
    }

    const handleClickOutsideColorPicker = async (event: any) => {
        if (
            sketchPickerRef.current &&
            !(sketchPickerRef.current as any).contains(event.target)
        ) {
            handleRequestColor(mainColor, secondaryColor);
        }
    };

    async function handleRequestColor(mColor: string, sColor: string) {
        const urlVerifyUserAccount: string = `${process.env.REACT_APP_ADMIN_ACCESS}/userColor`;

        await fetch(urlVerifyUserAccount, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: `email=${email}&mainColor=${mColor}&secondaryColor=${sColor}`,
            method: "POST",
        })
            .then((result) => result.json())
            .then(async (response: any) => {
                if (response.error) {
                }
            })
            .catch((err: any) => {
                return false;
            });
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutsideColorPicker);
        return () => {
            document.removeEventListener(
                "mousedown",
                handleClickOutsideColorPicker
            );
        };
        // eslint-disable-next-line
    }, [secondaryColor, mainColor]);

    return (
        <React.Fragment>
            <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="d-inline-block"
            >
                <DropdownToggle
                    className="btn header-item waves-effect  drop-down__clz"
                    id="page-header-user-dropdown"
                    tag="button"
                >
                    <Avatar name={name} size="40" round />
                    {menu !== true ? (
                        <BsCaretDownFill className="pt-2" fill="white" />
                    ) : (
                        <BsCaretUpFill className="pt-2" fill="white" />
                    )}
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem>
                        <div
                            style={{
                                width: "300px",
                                overflow: "hidden",
                            }}
                            className="d-flex justify-content-start"
                        >
                            <Avatar
                                className="mt-2 mb-2"
                                name={name}
                                round
                                size="70"
                                maxInitials={2}
                                color="#2196df"
                            />
                            <div className="pt-4 pl-3">
                                <div className="line1__clz">{name}</div>
                                <div className="line2__clz">{email}</div>
                            </div>
                        </div>
                    </DropdownItem>
                    <hr style={{ color: "#000000" }} />
                    <DropdownItem tag="a" onClick={() => changePassword()}>
                        <KeySvgIcon height="20" width="20" />
                        <i className="bx bx-lock-open font-size-16 align-middle mr-1 ml-3"></i>
                        {t("Change password")}
                    </DropdownItem>
                    <DropdownItem
                        style={{ pointerEvents: "none" }}
                        className="dropdown-item pl-4"
                    >
                        <MagicSvgIcon height="25" width="25" fill="black" />
                        <i className="bx bx-power-off font-size-16 align-middle text-danger ml-3"></i>

                        <span>{t("Appearance")}</span>
                    </DropdownItem>
                    <DropdownItem
                        className="dropdown-item pl-5"
                        toggle={false}
                        onBlur={() => setColorMainPicker(false)}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <span
                                style={{
                                    pointerEvents: "none",
                                    cursor: "default",
                                }}
                                className="ml-3 mr-3"
                            >
                                {t("Main color")}
                            </span>
                            <div
                                style={{
                                    borderRadius: 3,
                                    width: 60,
                                    height: 20,
                                    backgroundColor: mainColor,
                                    marginRight: 10,
                                }}
                            ></div>

                            <Tooltip title={t("Pick main color")}>
                                <span
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <ColorSvgIcon
                                        onClick={handleChangeMainColor}
                                        height="20"
                                        width="20"
                                        color={mainColor}
                                    />
                                </span>
                            </Tooltip>
                            <Tooltip title={t("Restore main color")}>
                                <span
                                    onClick={handleResetColor}
                                    className="pl-3"
                                >
                                    <RestoreSvgIcon
                                        height="20"
                                        width="20"
                                        stroke={"#171616"}
                                    />
                                </span>
                            </Tooltip>
                            {colorMainPicker ? (
                                <div
                                    style={{
                                        position: "fixed",
                                        top: "75%",
                                        right: "14%",
                                    }}
                                    ref={sketchPickerRef}
                                >
                                    <Card>
                                        <CardBody>
                                            <SketchPicker
                                                color={mainColor}
                                                onChangeComplete={(
                                                    color: any
                                                ) => {
                                                    localStorage.setItem(
                                                        "mainColor",
                                                        color.hex
                                                    );
                                                    setMainColor(color.hex);
                                                }}
                                            />
                                        </CardBody>
                                    </Card>
                                </div>
                            ) : null}
                        </div>
                    </DropdownItem>
                    <DropdownItem
                        style={{ display: "none" }}
                        toggle={false}
                        className="dropdown-item pl-5"
                        onBlur={() => setColorSecondaryMainPicker(false)}
                    >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <span className="ml-3">{t("Secondary color")}</span>
                            <div
                                style={{
                                    borderRadius: 3,
                                    width: 60,
                                    height: 20,
                                    backgroundColor: secondaryColor,
                                    marginRight: 10,
                                    marginLeft: 8,
                                }}
                            ></div>
                            <Tooltip title={t("Pick secondary color")}>
                                <span onClick={handleChangeSecondaryColor}>
                                    <ColorSvgIcon
                                        height="20"
                                        width="20"
                                        color={secondaryColor}
                                    />
                                </span>
                            </Tooltip>

                            <Tooltip title={t("Restore secondary color")}>
                                <span
                                    onClick={handleSecondaryResetColor}
                                    className="pl-3"
                                >
                                    <RestoreSvgIcon
                                        height="20"
                                        width="20"
                                        stroke={"#c4bfbe"}
                                    />
                                </span>
                            </Tooltip>
                            {colorSecondaryPicker ? (
                                <div
                                    ref={sketchPickerRef}
                                    style={{
                                        position: "fixed",
                                        top: "86%",
                                        right: "14%",
                                    }}
                                >
                                    <SketchPicker
                                        color={secondaryColor}
                                        onChangeComplete={(color: any) => {
                                            localStorage.setItem(
                                                "secondaryColor",
                                                color.hex
                                            );
                                            setSecondaryColor(color.hex);
                                        }}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </DropdownItem>
                    <DropdownItem
                        style={{ cursor: "pointer" }}
                        onClick={() => logout()}
                        className="dropdown-item"
                    >
                        <LogOutSvgIcon height="25" width="25" fill="red" />
                        <i className="bx bx-power-off font-size-16 align-middle text-danger ml-3"></i>
                        <span>{t("Logout")}</span>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
}
