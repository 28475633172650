import React from "react";

export default function AndroidSvgIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={42.115}
            height={49.773}
            data-name="android-logo (1)"
            {...props}
        >
            <g data-name="Groupe 30">
                <path
                    d="M3.081 16.122a2.971 2.971 0 0 0-2.183.9 2.937 2.937 0 0 0-.9 2.154v12.862a3.064 3.064 0 0 0 3.081 3.081 2.926 2.926 0 0 0 2.169-.9 3 3 0 0 0 .882-2.184V19.173a3.063 3.063 0 0 0-3.051-3.051Z"
                    data-name="Trac\xE9 6"
                />
                <path
                    d="M27.847 4.576 29.971.659a.394.394 0 0 0-.149-.6.414.414 0 0 0-.6.179l-2.153 3.949a15 15 0 0 0-12.025 0L12.892.239a.415.415 0 0 0-.6-.179.393.393 0 0 0-.149.6l2.124 3.918A13.115 13.115 0 0 0 9.122 9.17a11.6 11.6 0 0 0-1.915 6.446h27.668a11.6 11.6 0 0 0-1.917-6.448 13.171 13.171 0 0 0-5.111-4.592Zm-12.279 5.7a1.121 1.121 0 0 1-.823.345 1.07 1.07 0 0 1-.807-.345 1.193 1.193 0 0 1 0-1.645 1.074 1.074 0 0 1 .807-.344 1.163 1.163 0 0 1 .823 1.988Zm12.607 0a1.073 1.073 0 0 1-.807.345 1.167 1.167 0 0 1 0-2.333 1.075 1.075 0 0 1 .807.344 1.193 1.193 0 0 1 0 1.645Z"
                    data-name="Trac\xE9 7"
                />
                <path
                    d="M7.328 36.611a3.267 3.267 0 0 0 3.29 3.29h2.214l.03 6.791a2.973 2.973 0 0 0 .9 2.184 2.937 2.937 0 0 0 2.154.9 3.064 3.064 0 0 0 3.081-3.081v-6.79h4.128v6.79a3.081 3.081 0 1 0 6.161 0v-6.79h2.243a3.267 3.267 0 0 0 3.26-3.29V16.69H7.328Z"
                    data-name="Trac\xE9 8"
                />
                <path
                    d="M39.035 16.122a2.964 2.964 0 0 0-2.154.882 2.925 2.925 0 0 0-.9 2.168v12.862a2.971 2.971 0 0 0 .9 2.184 2.938 2.938 0 0 0 2.154.9 3.064 3.064 0 0 0 3.081-3.081V19.173a2.925 2.925 0 0 0-.9-2.168 3 3 0 0 0-2.181-.883Z"
                    data-name="Trac\xE9 9"
                />
            </g>
        </svg>
    );
}
