import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Router } from "@components/Router";

import "bootstrap/dist/css/bootstrap.css";

export default function App() {
    return (
        <BrowserRouter>
            <Router />
        </BrowserRouter>
    );
}
