import React from "react";
import { Container } from "reactstrap";
import { Tooltip } from "@mui/material";
import { Dropdown, DropdownToggle } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import { store } from "./store";

import LanguageDropdown from "@components/CommonForBoth/TopbarDropdown/LanguageDropdown";
import HomeSvgIcon from "@components/common/SvgIcons/HomeSvgIcon";

import ProfileMenu from "./ProfileMenu";

import "./index.css";

export function Header() {
    const { t } = useTranslation();
    const { mainColor } = useSnapshot(store);

    const [menu, setMenu] = React.useState<boolean>(false);
    return (
        <Container
            style={{ backgroundColor: mainColor }}
            fluid
            className="header-container__clz "
        >
            <img src="ETK-installer.png" alt="" />
            <div className="d-flex align-items-center">
                <LanguageDropdown />

                <Tooltip title={t("Single store display")}>
                    <div
                        style={{ cursor: "pointer", paddingLeft: "10px" }}
                        onClick={(e: any) => {
                            window.location.replace(
                                `${process.env.REACT_APP_URL_HOME}`
                            );
                        }}
                    >
                        <HomeSvgIcon className="pl-5" height={25} width={25} />
                    </div>
                </Tooltip>

                <Dropdown
                    isOpen={menu}
                    toggle={() => setMenu(!menu)}
                    className="d-inline-block"
                >
                    <DropdownToggle
                        className="btn header-item waves-effect"
                        id="page-header-user-dropdown"
                        tag="button"
                    >
                        <ProfileMenu />
                    </DropdownToggle>
                </Dropdown>
            </div>
        </Container>
    );
}
