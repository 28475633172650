import React from "react";
import { PuffLoader } from "react-spinners";

export function SpinnerWrapper(): JSX.Element {
    return (
        <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100vh" }}
        >
            <PuffLoader color="#0c1b28" />
        </div>
    );
}
